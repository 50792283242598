/*
 *
 */
import React from 'react';
import styled from 'styled-components';


const BoxItem = styled.div`
	font-size:1.5rem;
	line-height:1.5em;
	margin:auto;
	max-width:48em;
	padding:1rem 1rem 1rem;
	box-sizing:border-box;
`;

const Box = styled.div`
	height:100%;
	padding:2.45rem 3rem;
	background:#fff;
	border:1px solid #eaeaea;
	border-radius:.35rem;
	text-align:left;
	box-sizing: border-box;
	
	box-shadow:0 0 2rem 0 rgba(0,0,0,.05);
	
	h3 {
		color:#161a1e;
		font-weight:500;
		font-size:1.7rem;
		margin:0 0 1em;
	}
	p {
		border-bottom:1px solid transparent;
		transition:all .3s cubic-bezier(0.23,1,0.32,1);
		word-break: break-word;
		margin:0px;
		margin-bottom:2rem;	
		text-align: justify;
		&:last-child {
			margin-bottom:0;
		}
	}
	
	a {
		display: flex;
		max-width: 100%;
	    font-size: 1.4rem;
	    color: #1b83a4;
	    background: #fff;
	    border: 1PX solid;
	    padding: 1.35rem 2.2rem;
	    border-radius: .35rem;
	    text-decoration: none;
	    transition: 0.3s;
	    font-weight: 500;
	    align-items: center;
    	justify-content: space-between;

	    &:hover {
		    color: #fff;
	    	background: #1b83a4;
	    	border-color: #1b83a4;

	    }
	}

	.arrow {
	  border: solid;
	  border-width: 0 3px 3px 0;
	  display: inline-block;
	  padding: 0.2em;
	  margin-left:20px;
	}

	.right {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

`;


/*
 *
 */
 
export default function SectionBox ({children}) {

	return (
			<BoxItem>
				<Box>
					{children}
				</Box>
			</BoxItem>
	);
}