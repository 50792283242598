/*
 *
 */

// Vendors
import React from 'react';
import { Link } from 'gatsby';
import { useIntl, FormattedMessage } from 'react-intl';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Helmet from 'react-helmet';

// Components
import Section from 'components/section';
import Partners from 'components/partners';
import Text from 'components/text';
import Button from 'components/button';
import SectionBox from 'components/section-box';
import HomeIntro from 'components/home-intro';
import RegionList from 'components/region-list';
import Press from 'components/press';
import ShareBox from 'components/sharebox';
import AnnouncementSection from 'components/annoucement'

import { DEFAULT_LANG } from 'i18n';

// Data
import regions from 'data/regions.json';

/*
 *
 */
export default function Home () {

	const intl = useIntl();
	const lang = intl.locale;

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.home.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.home.meta_desc' })} />
			</Helmet>
			<Section>
				<HomeIntro
					title={intl.formatMessage({ id: 'page.home.title' })}
					subtitle={intl.formatMessage({ id: 'page.home.tagline' })}
					hashtag={intl.formatMessage({ id: 'page.home.hashtag'})}
				/>
			</Section>
			<Section>
				<Text>
					<h2>
						{intl.formatMessage({ id: 'page.home.section.1.title' })}
					</h2>
					<div />
				</Text>
				<RegionList regions={regions} />
				<ShareBox
					title={intl.formatMessage({ id: 'page.home.sharebox.title' })}
					subtitle={intl.formatMessage({ id: 'page.home.sharebox.subtitle' })}
					websiteToShare={intl.formatMessage({ id: 'page.home.sharebox.sharedlink' })}
				/>
			</Section>
			<AnnouncementSection>
				<p>{intl.formatMessage({ id: 'page.region.alert.qr-code-menu.desc'})}<a href={`${lang !== DEFAULT_LANG ? `/${lang}` : ''}/qr-code-menu`}>{intl.formatMessage({ id: 'page.region.alert.qr-code-menu.link'})}</a></p>
			</AnnouncementSection>
			{ intl.locale === 'fr' ?
				<Section>
					<Text>
						<h2>
							{intl.formatMessage({ id: 'page.home.press.title' })}
						</h2>
						<div />
					</Text>
						<Press
						press={[
							{
								name: 'La Une',
								logo: '/img/la-une.png',
								url: 'https://www.loom.com/share/06fc591151a047caa02efd4af8da1e40'
							},
							{
								name: 'La Libre',
								logo: '/img/lalibre.png',
								url: 'https://www.lalibre.be/economie/entreprises-startup/coronavirus-un-site-internet-pour-savoir-quels-restaurants-sont-ouverts-en-belgique-5e7b56899978e2284142331a'
							},
							{
								name: 'RTBF',
								logo: '/img/rtbf.png',
								url: 'https://www.rtbf.be/tendance/cuisine/bonnes-adresses/detail_confinement-retrouvez-les-commerces-de-proximite-encore-actifs-qui-ont-besoin-de-soutien?id=10468893'
							},
							{
								name: 'Flair',
								logo: '/img/flair.png',
								url: 'https://www.flair.be/fr/food-fr/ce-site-rassemble-tous-les-restaurants-de-belgique-ouverts-durant-le-confinement/'
							},
							{
								name: 'Geeko',
								logo: '/img/geeko.png',
								url: 'https://geeko.lesoir.be/2020/03/25/coronavirus-un-site-web-qui-repertorie-les-restaurants-qui-restent-en-activite/'
							}
						]}
					/>
				</Section>
			: null }
			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.home.section.howtohelp.title' })}</h2>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.intro' })}</p>
					<div></div>
					<div></div>

				</Text>
				<SectionBox>	
					<h3>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1' })}</h3>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1.p_1' })}</p>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1.p_2' })}</p>
					<Link 
						to={`${lang !== DEFAULT_LANG ? `/${lang}` : ''}/list`}
						onClick={e => {
					        // Lets track that custom click
					        trackCustomEvent({
					          // string - required - The object that was interacted with (e.g.video)
					          category: "Button",
					          // string - required - Type of interaction (e.g. 'play')
					          action: "View List of Restaurant",
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
					          label: "Home - How to Help"
					        })
			      		}}>
						<span>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1.link.text' })}</span>
						<i className="arrow right" />
					</Link>
				</SectionBox>
				<SectionBox>
					<h3>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_2' })}</h3>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_2.p_1' })}</p>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_2.p_2' })}</p>
					<a 
						rel="nofollow noindex noreferrer noopener" 
						target="_blank" 
						href={intl.formatMessage({ id: 'page.home.section.howtohelp.q_2.link' })}
						onClick={e => {
					        // Lets track that custom click
					        trackCustomEvent({
					          // string - required - The object that was interacted with (e.g.video)
					          category: "Button",
					          // string - required - Type of interaction (e.g. 'play')
					          action: "Visit Aidons Nos Restaurants",
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
					          label: "Home - How to Help"
					        })
			      		}}>
						<span>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_2.link.text' })}</span>
						<i className="arrow right" />
					</a>
				</SectionBox>
			</Section>
			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.home.section.4.title' })}</h2>
					<p>
						<FormattedMessage
							id="page.home.section.4.p_1"
							values={{
								a: (...chunks) => (
									<a href="mailto:hello@aide-aux-restaurateurs.be">
										{chunks}
									</a>
								)
							}}
						/>
					</p>

					<h3>{intl.formatMessage({ id: 'page.home.section.4.form' })}</h3>
					<p>
						<Button 
							target="_blank" 
							href={intl.formatMessage({ id: 'global.link.add_restaurant' })}
							onClick={e => {
					        // Lets track that custom click
					        trackCustomEvent({
					          // string - required - The object that was interacted with (e.g.video)
					          category: "Button",
					          // string - required - Type of interaction (e.g. 'play')
					          action: "Add Restaurant",
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
					          label: "Homepage - Add Section"
					        })
					      }}>
							{intl.formatMessage({ id: 'page.home.section.4.add_restaurant' })}
						</Button>
					</p>
				</Text>
			</Section>
			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.home.section.faq.title' })}</h2>
					<p>{intl.formatMessage({ id: 'page.home.section.faq.subtitle' })}</p>
					<p>
						<Button to={`${lang !== DEFAULT_LANG ? `/${lang}` : ''}/faq`}>
							{intl.formatMessage({ id: 'page.home.section.faq.cta' })}
						</Button>
					</p>
				</Text>
			</Section>
			<Section>
				<Text>

					<h2>{intl.formatMessage({ id: 'page.home.section.about.title' })}</h2>
					<p>{intl.formatMessage({ id: 'page.home.section.about.subtitle' })}</p>
				</Text>
					<Partners
						partners={[
							{
								name: 'CentralApp',
								description: intl.formatMessage({ id: 'page.home.partners.centralapp.desc' }),
								logo: '/img/centralapp.png',
								url: 'https://centralapp.com'
							},
							{
								name: 'Deliveroo',
								description: intl.formatMessage({ id: 'page.home.partners.deliveroo.desc' }),
								logo: '/img/deliveroo.png',
								url: 'http://deliveroo.be'
							},
							{
								name: 'Choco',
								description: intl.formatMessage({ id: 'page.home.partners.choco.desc' }),
								logo: '/img/choco.png',
								url: 'https://choco.com/fr-2/fr'
							},
							{
								name: 'TheFork',
								description: intl.formatMessage({ id: 'page.home.partners.thefork.desc' }),
								logo: '/img/thefork.png',
								url: 'https://thefork.be'
							},
							{
								name: 'Oyé Oyé',
								description: intl.formatMessage({ id: 'page.home.partners.oyeoye.desc' }),
								logo: '/img/oyeoye.png',
								url: 'https://oye-oye.net/'
							},
							{
								name: 'Petit Futé',
								description: intl.formatMessage({ id: 'page.home.partners.petitfute.desc' }),
								logo: '/img/petitfute.png',
								url: 'http://petitfute.be'
							},
							{
								name: 'Bancontact Payconiq Company',
								description: intl.formatMessage({ id: 'page.home.partners.payconiq.desc' }),
								logo: '/img/payconiqbybancontact.png',
								url: 'https://www.bancontactpayconiq.com/fr'
							}
		
						]}
					/>
				<Text>
					<p style={{ textAlign: 'center' }}>
						<FormattedMessage
							id="page.home.section.about.illustrations"
							values={{
								a: (...chunks) => (
									<a target="_blank" rel="noopener noreferrer" href="https://instagram.com/alixdebroux">
										{chunks}
									</a>
								)
							}}
						/>
					</p>
				</Text>
			</Section>
		</React.Fragment>
	);
}
