/*
 *
 */
import React from 'react';
import styled, { css } from 'styled-components';

/*
 * Styles
 */
const Root = styled.div`
	width:100%;
	padding:0 3rem 3rem;
	box-sizing:border-box;

	@media (${props => props.theme.media.phone_large}) {
		display:flex;
		padding:3rem;

		align-items:center;
	}

	@media (${props => props.theme.media.tablet}) {
		padding:0;
	}

`;

/*
 *
 */
const Illustration = styled.div`

	${({ type }) => {
		switch (type) {
			case 'pack-restart':
				return css`
					background:url(/img/super-chef.png);
				`;
			case 'home':
				default:
				return css`
					background:url(/img/illustration.png);
				`;
		}
	}}

	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;

	&::before {
		padding-top:75%;
		content:"";
		display:block;
	}

	@media (${props => props.theme.media.phone_large}) {
		width:45%;
	}
`;

/*
 *
 */
const Content = styled.div`
	box-sizing:border-box;
	text-align:center;

	@media (${props => props.theme.media.phone_large}) {
		padding-left:3rem;
		width:55%;

		display:flex;
		flex-direction:column;
		justify-content:center;

		text-align:left;
	}
`;

/*
 *
 */
const Title = styled.h1`
	margin:0 0 .66em;

	line-height:1.1em;
	font-size:2.6rem;
	font-weight:500;

	color:#1b83a4;

	@media (${props => props.theme.media.phone_large}) {
		margin:0 0 .35em;
		font-size:3rem;
	}

	@media (${props => props.theme.media.tablet}) {
		font-size:4rem;
	}

`;

/*
 *
 */
const Subtitle = styled.p`
	margin:0;

	font-size:1.5rem;
	font-weight:200;
	line-height:1.3em;

	@media (${props => props.theme.media.phone_large}) {
		font-size:2.2rem;
		max-width:none;
	}
`;

const Hasthag = styled.p`
	margin-top:30px;
 	font-size:1.5rem;
	font-weight:50à;
 	@media (${props => props.theme.media.phone_large}) {
		font-size:2rem;
		max-width:none;
	}
`;


const LinkCta = styled.a`


	margin-top:2rem;
	display:inline-block;

	font-size:1.55rem;
	font-weight:500;
	
	color:#fff !important;
	background:#1b83a4;
	padding:1.75rem 3.6rem;
	border-radius:.35rem;
	text-decoration:none;
	transition:0.2s;

	&:hover {
		background:#156A86;
	}
`;

/*
 *
 */
export function HomeIntro ({ title, subtitle, hashtag, ctaLink, ctaText, illustration }) {
	return (
		<Root>
			<Illustration type={illustration} />
			<Content>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
				{hashtag ? <Hasthag>{hashtag}</Hasthag> : null}
				{ctaLink ? <p><LinkCta target="_blank" href={ctaLink}>{ctaText}</LinkCta></p> : null}
			</Content>
		</Root>
	);
}

export default HomeIntro;